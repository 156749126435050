import { Component, OnInit } from '@angular/core';

import { EventService } from '../core/services/event.service';
import { LAYOUT } from './layout.model';
import { LayoutStorageService } from '../core/services/layout-storage.service';
import { LoaderService } from '../core/services/loader.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

/**
 * Layout Component
 */
export class LayoutComponent implements OnInit {

  layoutType!: string;

  constructor(private eventService: EventService, private layoutStorageService: LayoutStorageService, private loaderService: LoaderService) {
   }

  async ngOnInit() {
    let layout = this.layoutStorageService.get();
    this.layoutType = layout?.portalPreferences?.LAYOUT ?? LAYOUT;

     // listen to event and change the layout, theme, etc
     this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });
    // this.loaderService.disableLoader();
  }

  /**
  * Check if the vertical layout is requested
  */
   isVerticalLayoutRequested() {
    return this.layoutType === 'vertical';
   }
  
  /**
   * Check if the semibox layout is requested
   */
   isSemiboxLayoutRequested() {
    return this.layoutType === 'semibox';
  }

  /**
   * Check if the horizontal layout is requested
   */
   isHorizontalLayoutRequested() {
    return this.layoutType === 'horizontal';
  }

  /**
   * Check if the horizontal layout is requested
   */
   isTwoColumnLayoutRequested() {
    return this.layoutType === 'twocolumn';
  }

}

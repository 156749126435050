<!-- Vertical layout -->
<app-vertical *ngIf="isVerticalLayoutRequested()"></app-vertical>

<!-- Horizontal layout -->
<app-horizontal *ngIf="isHorizontalLayoutRequested()"></app-horizontal>

<!-- Two Columns layout -->
<app-two-column *ngIf="isTwoColumnLayoutRequested()"></app-two-column>

<!-- Semibox layout -->
<app-vertical *ngIf="isSemiboxLayoutRequested()"></app-vertical>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Component pages
import { DashboardComponent } from "./dashboards/dashboard/dashboard.component";
import { UsuarioListagemComponent } from './usuarios/usuario-listagem/usuario-listagem.component';
import { UsuarioInclusaoComponent } from './usuarios/usuario-inclusao/usuario-inclusao.component';

const routes: Routes = [
    {
        path: "",
        component: DashboardComponent,
        title: "RhinoSoft - Home"
    },
    {
        path: "usuario",
        component: UsuarioListagemComponent,
        title: "RhinoSoft - Usuario"
    },
    {
        path: "usuario/inclusao",
        component: UsuarioInclusaoComponent,
        title: "RhinoSoft - Usuario"
    },
    {
        path: "usuario/:id",
        component: UsuarioInclusaoComponent,
        title: "RhinoSoft - Usuario"
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }

import { Injectable } from "@angular/core";
import { GlobalComponent } from "src/app/global-component";
import { UserPagination } from "../models/user.pagination.model";
import axios from "../core/helpers/axios.interceptor";
import { User } from "../models/user.model";

const API_URL = GlobalComponent.API_URL;
const ROUTE = 'v1/users/'; 

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class UserService {
    get(pageIndex: number = 1, pageSize: number = 10){
        let headers = {'PageIndex': pageIndex, 'PageSize': pageSize};
        return axios<UserPagination>({
            headers: headers,
            method: 'GET',
            url: `${API_URL}${ROUTE}`
        });
    }

    getById(id: string){
        return axios<User>({
            method: 'GET',
            url: `${API_URL}${ROUTE}${id}`
        });
    }

    filter(key: string){
        return axios<UserPagination>({
            method: 'GET',
            url: `${API_URL}${ROUTE}filter/${key}`
        });
    }

    delete(id: string){
        return axios({
            method: 'DELETE',
            url: `${API_URL}${ROUTE}${id}`
        });
    }

    add(user: User){
        return axios({
            data: user,
            method: 'POST',
            url: `${API_URL}${ROUTE}`
        });
    }

    update(user: User, id: String){
        return axios({
            data: user,
            method: 'PUT',
            url: `${API_URL}${ROUTE}${id}`
        });
    }
}
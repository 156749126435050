<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title mb-0">{{ "USER.USERLISTING.USERLISTING" | translate }}</h4>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="listjs-table" id="customerList">
                    <div class="row g-4 mb-3">
                        <div class="col-sm-auto">
                            <div>
                                <button type="button" class="btn btn-success add-btn"
                                    id="create-btn" (click)="navigateAdd()"><i
                                        class="ri-add-line align-bottom me-1"></i> {{ "USER.USERLISTING.ADD" | translate }}</button>
                                <button class="btn btn-soft-danger" *ngIf="false"><i
                                        class="ri-delete-bin-2-line"></i></button>
                            </div>
                        </div>
                        <div class="col-sm">
                            <div class="d-flex justify-content-sm-end">
                                <div class="search-box ms-1">
                                    <select class="form-select" (change)="getUsers()" [ngModelOptions]="{standalone: true}" [(ngModel)]="selQuantReg" aria-label="Quantity of Registries">
                                        <option selected value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                </div>
                                <div class="search-box ms-2">
                                    <form (ngSubmit)="filter()">
                                        <input type="text" class="form-control search" placeholder="Search..." [ngModelOptions]="{standalone: true}" [(ngModel)]="txtFilter" #ctrl="ngModel" required>
                                        <i class="ri-search-line search-icon"></i>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="containRecords">
                        <div class="table-responsive table-card mt-3 mb-1">
                            <table class="table align-middle table-nowrap" id="customerTable">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col" style="width: 50px;">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="checkAll"
                                                    value="option">
                                            </div>
                                        </th>
                                        <th class="sort" data-sort="customer_name">{{ "USER.USERLISTING.NAME" |
                                            translate }}</th>
                                        <th class="sort" data-sort="email">E-mail</th>
                                        <th class="sort" data-sort="email">{{ "USER.USERLISTING.STATUS" |
                                            translate }}</th>
                                        <th class="sort" data-sort="email">{{ "USER.USERLISTING.ACTIONS" |
                                            translate }}</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                    <tr *ngFor="let user of users">
                                        <th scope="row">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" name="chk_child"
                                                    value="option1">
                                            </div>
                                        </th>
                                        <td class="id" style="display:none;"><a href="javascript:void(0);"
                                                class="fw-medium link-primary">#VZ10</a></td>
                                        <td class="customer_name">{{ user.name }}</td>
                                        <td class="email">{{ user.email }}</td>
                                        <td class="status"><span
                                                class="badge bg-success-subtle text-success text-uppercase">{{ "USER.USERLISTING.ACTIVE" |
                                                translate }}</span>
                                        </td>
                                        <td>
                                            <div class="d-flex gap-2">
                                                <div class="edit">
                                                    <button class="btn btn-sm btn-success edit-item-btn" (click)="navigateEdit(user.id)"><i
                                                            class="ri-edit-2-line"></i></button>
                                                </div>
                                                <div class="remove">
                                                    <button class="btn btn-sm btn-danger remove-item-btn"
                                                        data-bs-toggle="modal" data-bs-target="#deleteRecordModal"><i
                                                            class="ri-delete-bin-line" (click)="deleteById(user.id)"></i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="d-flex justify-content-end">
                            <div class="pagination-wrap hstack gap-2" style="display: flex;">
                                <button class="page-item pagination-prev disabled" [ngClass]="{'disabled': isPreviousDisabled() }" href="javascript:void(0);" (click)="previousPage()">
                                    {{ "USER.USERLISTING.PREVIOUS" | translate }}
                                </button>
                                <ul class="pagination listjs-pagination mb-0">
                                    <li *ngFor="let page of getPageRange(); let i = index" [class.active]="currentPage === page">
                                        <a class="page" href="javascript:void(0);" (click)="setCurrentPage(page)">{{ page }}</a>
                                    </li>
                                </ul>
                                <button class="page-item pagination-next disabled" [ngClass]="{'disabled': isNextDisabled() }" href="javascript:void(0);" (click)="nextPage()">
                                    {{ "USER.USERLISTING.NEXT" | translate }} 
                                </button>
                            </div>
                        </div>

                    </div>

                    <div class="card-body" *ngIf="!containRecords">
                        <div class="noresult">
                            <div class="text-center">
                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                    colors="primary:#121331,secondary:#08a88a"
                                    style="width:75px;height:75px"></lord-icon>
                                <h5 class="mt-2">Sorry! No Result Found</h5>
                                <p class="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                    orders for you search.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
    <!-- end col -->
</div>
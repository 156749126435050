import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
    role: "user:read"
  },
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARD.TEXT',
    icon: 'ri-dashboard-2-line',
    collapseid: 'sidebarDashboards',
    role: "user:read",
    subItems: [
      {
        id: 3,
        label: 'MENUITEMS.DASHBOARD.LIST.ECOMMERCE',
        link: '',
        parentId: 2
      }
    ]
  },
  {
    id: 4,
    label: 'MENUITEMS.PAGES.TEXT',
    icon: 'ri-user-line',
    collapseid: 'sidebarUsers',
    role: "user:read",
    subItems: [
      {
        id: 5,
        label: 'MENUITEMS.USER.TEXT',
        parentId: 4,
        subItems: [
          {
            id: 13,
            label: 'MENUITEMS.COMMON.LIST.LISTING',
            link: '/usuario',
            parentId: 11
          },
          {
            id: 13,
            label: 'MENUITEMS.COMMON.LIST.INCLUDE',
            link: '/usuario/inclusao',
            parentId: 11
          },
        ]
      }
    ]
  }
];

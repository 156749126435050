import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    constructor() { }
    /***
     * Get All User
     */
    disableLoader() {
      (document.getElementById("preloader") as HTMLElement).style.opacity = "0";
      (document.getElementById("preloader") as HTMLElement).style.visibility = "hidden";
    }

    enableLoader(){
        (document.getElementById("preloader") as HTMLElement).style.opacity = "100";
        (document.getElementById("preloader") as HTMLElement).style.visibility = "visible";
    }
}

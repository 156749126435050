import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from '../../dashboards/dashboard/toast-service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-usuario-inclusao',
  templateUrl: './usuario-inclusao.component.html',
  styleUrls: ['./usuario-inclusao.component.scss']
})
export class UsuarioInclusaoComponent implements OnInit {
  /**
   *
   */
  submitted!: boolean;
  userForm!: UntypedFormGroup;
  fieldTextType!: boolean;
  pages!: Array<any>;
  perms: Array<string> = [];
  userId?: string;
  status?: number = 1;


  constructor(
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    public toastService: ToastService,
    private route: ActivatedRoute,
    private loaderService: LoaderService) {
    translate.setDefaultLang('pt-br');
  }

  async ngOnInit() {
    this.translate.get("USER.TEXT").subscribe((res: string) => {
      document.title = "Rhinosoft - " + res;
    });

    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: this.passwordMatchValidator
    });
    this.loadData();
    this.mockPages();
  }

  loadData() {
    this.loaderService.enableLoader();
    this.route.params.subscribe(async params => {
      // Aqui você pode acessar os parâmetros da query string.
      this.userId = params['id'];

      if (this.userId) {
        try {
          document.documentElement.setAttribute('data-preloader', 'disabled');
          let user = await this.userService.getById(this.userId);
          this.perms = user.data.roles;
          this.userForm = this.formBuilder.group({
            name: [user.data.name, [Validators.required]],
            email: [user.data.email, [Validators.required, Validators.email]],
            password: ['*******', [Validators.required]],
            confirmPassword: ['*******', [Validators.required]],
          }, {
            validator: this.passwordMatchValidator
          });
          this.status = user.status;
        } catch (error) {
          document.documentElement.setAttribute('data-preloader', 'disabled');
          this.toastService.show(
            'Ocorreu um erro, entre em contato com o administrador.',
            { classname: 'bg-danger text-white', delay: 15000 }
          );
        }
        finally {
          this.loaderService.disableLoader();
        }
      } 
      else {
        this.loaderService.disableLoader();
      }
    });
  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if (password?.value === confirmPassword?.value) {
      return null;
    } else {
      return { 'passwordMismatch': true };
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  get f() { return this.userForm.controls; }

  async onSubmit() {
    this.submitted = true;

    if (this.userForm.valid) {
      let user: User = {
        id: '',
        name: this.f['name'].value,
        email: this.f['email'].value,
        businessPartner: ['RhinoSoft'],
        password: this.f['password'].value,
        roles: this.perms,
        status: this.status
      }
      try {
        if (this.userId){
          await this.userService.update(user, this.userId);
          this.router.navigate(['/usuario']);
        }
        else {
          await this.userService.add(user);
          this.router.navigate(['/usuario']);
        }
      }
      catch (error) {
        this.toastService.show(
          'Ocorreu um erro, entre em contato com o administrador.',
          { classname: 'bg-danger text-white', delay: 15000 }
        );
      }
    }
  }

  mockPages() {
    this.pages = [{
      description: "Usuário",
      name: "user",
    }, {
      description: "Teste",
      name: "test"
    }, {
      description: "Example",
      name: "example"
    },]
  }

  handleCheckboxChange(event: any, perm: string) {
    if (event.target.checked) {
      this.perms.push(perm);
    } else {
      this.perms = this.perms.filter(item => item !== perm);
    }
  }

  isChecked(value: string): boolean {
    return this.perms.includes(value);
  }
}

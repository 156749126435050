import axios from 'axios';
import { TokenStorageService } from '../services/token-storage.service';

const tokenStorage = new TokenStorageService();

axios.interceptors.request.use(function (config) {
    const token = tokenStorage.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.set("Language", tokenStorage.getCookie('lang'));
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

export default axios;
import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public removeToken(): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY); // Use sessionStorage aqui
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public getJwtId() {
    var decodedJwt = this.getDecodedJwt();

    return decodedJwt.Id;
  }

  public getRoles() : string[] {
    var decodedJwt = this.getDecodedJwt();
    let userRoles: string[] = (decodedJwt as any)['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || [];

    return userRoles;
  }

  private getDecodedJwt() : any {
    let jwt = this.getToken() ?? "";
    
    return jwt_decode(jwt);
  }

  public getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts && parts.length === 2) 
      return parts.pop()?.split(';').shift();
    else
      return '';
  }
}
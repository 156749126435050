<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1">{{ "USER.USERINSERT.BASICINFO" | translate }}</h4>
                </div>
                <div class="card-body">
                    <div class="row gy-4">
                        <div class="col-xxl-3 col-md-6">
                            <div>
                                <label for="name" class="form-label">{{ "USER.USERINSERT.NAME" | translate }}</label>
                                <div class="form-icon">
                                    <input type="text" class="form-control form-control-icon" id="name"
                                        [ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
                                        formControlName="name" />
                                    <i class=" ri-pencil-line"></i>
                                </div>
                            </div>
                            <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                                <div *ngIf="f['name'].errors['required']">{{ "USER.USERINSERT.VALIDATIONS.NAMEREQUIRED" | translate }}</div>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-md-6">
                            <div>
                                <label for="email" class="form-label">E-mail</label>
                                <div class="form-icon">
                                    <input type="email" class="form-control form-control-icon" id="email"
                                        [ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
                                        formControlName="email">
                                    <i class="ri-mail-unread-line"></i>
                                </div>
                            </div>
                            <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                <div *ngIf="f['email'].errors['required']">{{ "USER.USERINSERT.VALIDATIONS.EMAILREQUIRED" | translate }}</div>
                                <div *ngIf="f['email'].errors['email']">{{ "USER.USERINSERT.VALIDATIONS.INVALIDEMAIL" | translate }}</div>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-md-6">
                            <div>
                                <label for="password" class="form-label">{{ "USER.USERINSERT.PASSWORD" |
                                    translate }}</label>
                                <div class="form-icon">
                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control form-control-icon" id="password"
                                        formControlName="password" minlength="6"
                                        [ngClass]="{ 'is-invalid': submitted && f['name'].errors }">
                                    <i class="ri-shield-keyhole-line"></i>
                                    <button class="btn btn-link position-absolute eye-password-icon text-decoration-none text-muted"
                                        type="button" id="password-addon"><i class="mdi align-middle" 
                                        [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType }"
                                        (click)="toggleFieldTextType()"></i>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                <span *ngIf="f['password'].errors['required']">{{ "USER.USERINSERT.VALIDATIONS.PASSWORDREQUIRED" | translate
                                    }}</span>
                                <span *ngIf="f['password'].errors['minlength']">{{ "USER.USERINSERT.VALIDATIONS.PASSWORDLENGHT" | translate
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-md-6">
                            <div>
                                <label for="confirmPassword" class="form-label">{{ "USER.USERINSERT.CONFIRMPASSWORD" |
                                    translate }}</label>
                                <div class="form-icon">
                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control form-control-icon" id="confirmPassword"
                                        formControlName="confirmPassword" minlength="6"
                                        [ngClass]="{ 'is-invalid': submitted && f['name'].errors }">
                                    <i class="ri-shield-keyhole-line"></i>
                                    <button class="btn btn-link position-absolute eye-password-icon text-decoration-none text-muted"
                                        type="button" id="password-addon"><i class="mdi align-middle" 
                                        [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType }"
                                        (click)="toggleFieldTextType()"></i>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
                                <span *ngIf="f['confirmPassword'].errors['required']">{{ "USER.USERINSERT.VALIDATIONS.PASSWORDREQUIRED" |
                                    translate }}</span>
                                <span *ngIf="f['confirmPassword'].errors['minlength']">{{ "USER.USERINSERT.VALIDATIONS.PASSWORDLENGHT" |
                                    translate }}</span>
                            </div>
                            <div *ngIf="submitted && userForm.hasError('passwordMismatch')">
                                <span *ngIf="submitted && userForm.hasError('passwordMismatch')" class="invalid-feedback">
                                    As senhas não coincidem.
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="text-end">
                                <button type="submit" class="btn btn-primary">{{ "USER.USERINSERT.SUBMIT" |
                                    translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1">{{ "USER.USERINSERT.ROLES" | translate }}</h4>
                </div>
                <div class="card-body">
                    <div class="table-responsive table-card">
                        <table class="table table-nowrap table-striped-columns mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col" class="col-md-8 col-xs-8">{{ "USER.USERINSERT.PAGE" | translate }}
                                    </th>
                                    <th scope="col" class="col-md-1 col-xs-1">{{ "USER.USERINSERT.READ" | translate }}
                                    </th>
                                    <th scope="col" class="col-md-1 col-xs-1">{{ "USER.USERINSERT.INSERT" | translate }}
                                    </th>
                                    <th scope="col" class="col-md-1 col-xs-1">{{ "USER.USERINSERT.ALTER" | translate }}
                                    </th>
                                    <th scope="col" class="col-md-1 col-xs-1">{{ "USER.USERINSERT.DELETE" | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let page of pages; index as i">
                                    <td>{{page.description}}</td>
                                    <td>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" 
                                            name="{{page.name}}-read" 
                                            (change)="handleCheckboxChange($event, page.name+':read')" 
                                            [checked]="isChecked(page.name + ':read')" />
                                            <label class="form-check-label" for="cardtableCheck01"></label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" 
                                            name="{{page.name}}-create" 
                                            (change)="handleCheckboxChange($event, page.name+':create')" 
                                            [checked]="isChecked(page.name + ':create')" />
                                            <label class="form-check-label" for="cardtableCheck01"></label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" 
                                            name="{{page.name}}-update" 
                                            (change)="handleCheckboxChange($event, page.name+':update')"
                                            [checked]="isChecked(page.name + ':update')" />
                                            <label class="form-check-label" for="cardtableCheck01"></label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" 
                                            name="{{page.name}}-delete" 
                                            (change)="handleCheckboxChange($event, page.name+':delete')"
                                            [checked]="isChecked(page.name + ':delete')" />
                                            <label class="form-check-label" for="cardtableCheck01"></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
import { Injectable } from "@angular/core";
import { GlobalComponent } from "src/app/global-component";
import axios from "../core/helpers/axios.interceptor";
import { UserPreferences } from "../models/user-preferences.model";
import { TokenStorageService } from "../core/services/token-storage.service";
import { CookieService } from "ngx-cookie-service";
import { LayoutStorageService } from "../core/services/layout-storage.service";

const API_URL = GlobalComponent.API_URL;
const ROUTE = 'v1/users/'; 

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class UserPreferencesService {
    /**
     *
     */
    constructor(
        private cookieService: CookieService,
        private tokenService: TokenStorageService,
        private layoutService: LayoutStorageService) {
    }
    get(){
        let id = this.tokenService.getJwtId();
        return axios<UserPreferences>({
            method: 'GET',
            url: `${API_URL}${ROUTE}${id}/portal-preferences`
        });
    }

    patch(userPreferences: UserPreferences){
        let id = this.tokenService.getJwtId();
        if(id)
            return axios<UserPreferences>({
                method: 'PATCH',
                url: `${API_URL}${ROUTE}${id}/portal-preferences`,
                data: userPreferences
            });
        else
            return;
    }

    updatePortalPreferences() {
        let userPrefences = this.layoutService.get();
        if (userPrefences)
            this.patch(userPrefences);
    }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuario-listagem',
  templateUrl: './usuario-listagem.component.html',
  styleUrls: ['./usuario-listagem.component.scss']
})
export class UsuarioListagemComponent implements OnInit {
  users?: Array<User>;
  currentPage: number = 1;
  totalPages: number = 1;
  pageSize: number = 10;
  totalRecords: number = 10;
  containRecords?: boolean = false;
  txtFilter!: string;
  name: string = '';
  selQuantReg: number = 10;

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private loaderService: LoaderService) {
  }
  ngOnInit() {
    this.getUsers();
  }


  async getUsers() {
    this.translate.get("USER.TEXT").subscribe((res: string) => {
      document.title = "Rhinosoft - " + res;
    });
    this.loaderService.enableLoader();
    try {
      let response = (await this.userService.get(this.currentPage, this.selQuantReg)).data;
      this.currentPage = response.currentPage;
      this.totalPages = response.totalPages;
      this.pageSize = response.pageSize;
      this.totalRecords = response.totalRecords;
      this.users = response.data;
      this.countUsers();
    } catch (error: any) {
    }
    finally {
      this.loaderService.disableLoader();
    }
  }

  getPageRange(): number[] {
    const start = Math.max(1, this.currentPage - Math.floor(this.pageSize / 2));
    const end = Math.min(this.totalPages, start + this.pageSize - 1);

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  countUsers() {
    this.containRecords = (this.users && this.users.length > 0);
  }

  setCurrentPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.getUsers();
    }
  }

  previousPage(): void {
    this.setCurrentPage(this.currentPage - 1);
  }

  nextPage(): void {
    this.setCurrentPage(this.currentPage + 1);
  }

  isPreviousDisabled() {
    return this.currentPage <= 1;
  }

  isNextDisabled() {
    return this.currentPage >= this.totalPages;
  }

  navigateEdit(id: string) {
    this.router.navigate(['/usuario', id]);
  }

  deleteById(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#364574',
      cancelButtonColor: 'rgb(243, 78, 78)',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.userService.delete(id).then(() => {
          Swal.fire({ title: 'Deleted!', text: 'Your file has been deleted.', confirmButtonColor: '#364574', icon: 'success', }).then(() => {
            this.getUsers();
          });
        }).catch((error) => {
          Swal.fire({ title: 'Error!', text: 'An error has occurred.', confirmButtonColor: '#364574', icon: 'success', });
        });
      }
    });
  }

  async filter() {
    this.loaderService.enableLoader();
    if (this.txtFilter) {
      try {
        let response = (await this.userService.filter(this.txtFilter)).data;
        this.currentPage = response.currentPage;
        this.totalPages = response.totalPages;
        this.pageSize = response.pageSize;
        this.totalRecords = response.totalRecords;
        this.users = response.data;
        this.countUsers();
      } catch (error: any) {
      }
      finally {
        this.loaderService.disableLoader();
      }

    }
    else
      this.getUsers();
  }

  navigateAdd() {
    this.router.navigate(['/usuario/inclusao'])
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { UserPreferences } from 'src/app/models/user-preferences.model';
import { UserPreferencesService } from 'src/app/services/user-preferences.services';
import { LayoutStorageService } from './layout-storage.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {

  public languages: string[] = [ 'pt-br', 'en', 'es'];

  constructor(
    public translate: TranslateService, 
    private cookieService: CookieService,
    private userPrefencesServices: UserPreferencesService,
    private layoutService: LayoutStorageService) {

    let browserLang: any;
    /***
     * cookie Language Get
    */
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    }
    else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|pt-br/) ? browserLang : 'pt-br');
  }

  /***
   * Cookie Language set
   */
  public setLanguage(lang: any, update: boolean = true) {
    this.translate.use(lang);
    let layout = this.layoutService.get();
    layout.portalPreferences.Language = lang;
    this.layoutService.save(layout);
    if (update)
      this.userPrefencesServices.updatePortalPreferences();
  }

}

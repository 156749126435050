<!-- ========== App Menu ========== -->
<div class="app-menu navbar-menu">
  <!-- LOGO -->
  <div class="navbar-brand-box">
    <!-- Dark Logo-->
    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22">
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-dark.png" alt="" height="17">
      </span>
    </a>
    <!-- Light Logo-->
    <a routerLink="/" class="logo logo-light">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22">
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-light.png" alt="" height="17">
      </span>
    </a>
    <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover"
      (click)="toggleMobileMenu($event)">
      <i class="ri-record-circle-line"></i>
    </button>
  </div>

  <div id="scrollbar" class="h-100">
    <div class="container-fluid h-100">

      <div id="two-column-menu">
      </div>
      <ngx-simplebar class="sidebar-menu-scroll h-100">
        <ul class="metismenu list-unstyled navbar-nav" id="navbar-nav">

          <ng-container *ngFor="let item of menuItems">
            <li class="menu-title" *ngIf="item.isTitle"><span data-key="t-menu">{{ item.label | translate }}</span></li>

            <li class="nav-item" *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent nav-link menu-link"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" data-bs-toggle="collapse"
                aria-expanded="false" (click)="toggleItem($event)">
                <i class="{{ item.icon }} icon nav-icon" class=""></i>
                <span class=""> {{ item.label | translate }}</span>
                <span class="badge badge-pill {{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text
                  | translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref nav-link menu-link"
                (click)="toggleParentItem($event)">
                <i class="bx {{ item.icon }} icon nav-icon" *ngIf="item.icon" class=""></i>
                <span class=""> {{ item.label | translate }}</span>
                <span class="badge badge-pill {{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text
                  | translate}}</span>
              </a>

              <div class="collapse menu-dropdown" *ngIf="hasItems(item)">
                <ul *ngIf="hasItems(item)" class="nav nav-sm flex-column" aria-expanded="false">
                  <li *ngFor="let subitem of item.subItems" class="nav-item">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="nav-link"
                      [attr.data-parent]="subitem.parentId" (click)="updateActive($event)">
                      {{ subitem.label | translate}}
                      <span class="badge badge-pill {{subitem.badge.variant}}"
                        *ngIf="subitem.badge">{{subitem.badge.text
                        | translate}}</span>
                    </a>
                    <a *ngIf="hasItems(subitem)" class="nav-link" href="javascript:void(0);"
                      [attr.data-parent]="subitem.parentId" data-bs-toggle="collapse" aria-expanded="false"
                      (click)="toggleSubItem($event)">
                      {{ subitem.label | translate}}
                      <span class="badge badge-pill {{subitem.badge.variant}}"
                        *ngIf="subitem.badge">{{subitem.badge.text
                        | translate}}</span>
                    </a>

                    <div class="collapse menu-dropdown sub-menu" *ngIf="hasItems(subitem)">
                      <ul *ngIf="hasItems(subitem)" class="nav nav-sm flex-column" aria-expanded="false">
                        <li *ngFor="let subSubitem of subitem.subItems" class="nav-item">

                          <a [routerLink]="subSubitem.link" *ngIf="!hasItems(subSubitem)" class="nav-link"
                            [attr.data-parent]="subSubitem.parentId" (click)="updateActive($event)">
                            {{ subSubitem.label | translate}}
                            <span class="badge badge-pill {{subSubitem.badge.variant}}"
                              *ngIf="subSubitem.badge">{{subSubitem.badge.text
                              | translate}}</span>
                          </a>

                          <a *ngIf="hasItems(subSubitem)" class="nav-link" href="javascript:void(0);"
                            [attr.data-parent]="subSubitem.parentId" data-bs-toggle="collapse" aria-expanded="false"
                            (click)="toggleExtraSubItem($event)">
                            {{ subSubitem.label | translate}}
                            <span class="badge badge-pill {{subSubitem.badge.variant}}"
                              *ngIf="subSubitem.badge">{{subSubitem.badge.text
                              | translate}}</span>
                          </a>

                          <div class="collapse menu-dropdown extra-sub-menu" *ngIf="hasItems(subSubitem)">
                            <ul *ngIf="hasItems(subSubitem)" class="nav nav-sm flex-column" aria-expanded="false">
                              <li *ngFor="let subSubitem1 of subSubitem.subItems" class="nav-item">

                                <a [attr.data-parent]="subSubitem1.parentId" [routerLink]="subSubitem1.link"
                                  class="nav-link" (click)="updateActive($event)">
                                  {{ subSubitem1.label | translate }}
                                  <span class="badge badge-pill {{item.badge.variant}}"
                                    *ngIf="item.badge">{{item.badge.text
                                    | translate}}</span>
                                </a>

                              </li>
                            </ul>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ng-container>
        </ul>
      </ngx-simplebar>
    </div>
    <!-- Sidebar -->
  </div>
  <div class="sidebar-background"></div>
</div>
<!-- Left Sidebar End -->
<!-- Vertical Overlay-->
<div class="vertical-overlay" (click)="SidebarHide()"></div>
import { Injectable } from '@angular/core';
import { UserPreferences } from 'src/app/models/user-preferences.model';
import { User } from '../models/auth.models';

const LAYOUT_KEY = 'layout';

@Injectable({
  providedIn: 'root'
})
export class LayoutStorageService {
  constructor() { }

  signOut(): void {
    window.localStorage.clear();
  }

  public save(obj: UserPreferences): void {    
    window.localStorage.removeItem(LAYOUT_KEY);
    window.localStorage.setItem(LAYOUT_KEY, JSON.stringify(obj));
  }

  public get(): UserPreferences {
    let val = window.localStorage.getItem(LAYOUT_KEY);
    if (val)
        return JSON.parse(val) as UserPreferences;
    
    return { } as UserPreferences;
  }

  public remove(): void {
    window.localStorage.removeItem(LAYOUT_KEY);
  }
}
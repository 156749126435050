<!-- Start Breadcrumbs -->
<!-- <app-breadcrumbs title="DASHBOARD" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs> -->
<!-- End Breadcrumbs -->

<div class="row">
    <div class="col">
        <div class="h-100">
            <div class="row mb-3 pb-1">
                <div class="col-12">
                    <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div class="flex-grow-1">
                            <h4 class="fs-16 mb-1">Good Morning, Anna!</h4>
                            <p class="text-muted mb-0">Here's what's happening with your store today.</p>
                        </div>
                        <div class="mt-3 mt-lg-0">
                            <div class="row g-3 mb-0 align-items-center">
                                <div class="col-sm-auto">
                                    <div class="input-group">
                                        <input class="form-control flatpickr-input" type="text" mwlFlatpickr
                                            [(ngModel)]="currentDate" [altInput]="false" [convertModelValue]="true"
                                            [dateFormat]="'d M, Y'" mode="range">
                                        <div class="input-group-text bg-primary border-primary text-white"><i
                                                class="ri-calendar-2-line"></i></div>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-auto">
                                    <a routerLink="/ecommerce/add-product">
                                        <button type="button" class="btn btn-soft-success"><i
                                                class="ri-add-circle-line align-middle me-1"></i> Add
                                            Product</button></a>
                                </div>
                                <!--end col-->
                                <div class="col-auto">
                                    <button type="button"
                                        class="btn btn-soft-info btn-icon waves-effect waves-light layout-rightside-btn"
                                        (click)="toggleActivity()"><i class="ri-pulse-line"></i></button>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                    </div><!-- end card header -->
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div *ngFor="let stat of statData" class="col-xl-3 col-md-6">
                    <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"
                        [persantage]="stat.persantage" [profit]="stat.profit" [link]="stat.link"></app-stat>
                </div>
            </div> <!-- end row-->

            <div class="row">
                <div class="col-xl-8">
                    <div class="card">
                        <div class="card-header border-0 align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Revenue</h4>
                            <div class="d-flex gap-1">
                                <button type="button" class="btn btn-soft-secondary btn-sm"
                                    (click)="setrevenuevalue('all')">
                                    ALL
                                </button>
                                <button type="button" class="btn btn-soft-secondary btn-sm"
                                    (click)="setrevenuevalue('1M')">
                                    1M
                                </button>
                                <button type="button" class="btn btn-soft-secondary btn-sm"
                                    (click)="setrevenuevalue('6M')">
                                    6M
                                </button>
                                <button type="button" class="btn btn-soft-primary btn-sm"
                                    (click)="setrevenuevalue('1Y')">
                                    1Y
                                </button>
                            </div>
                        </div><!-- end card header -->

                        <div class="card-header p-0 border-0 bg-light-subtle">
                            <div class="row g-0 text-center">
                                <div class="col-6 col-sm-3">
                                    <div class="p-3 border border-dashed border-start-0">
                                        <h5 class="mb-1"><span [CountTo]="7585" class="counter-value" [from]="0"
                                                [duration]="1"></span></h5>
                                        <p class="text-muted mb-0">Orders</p>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-6 col-sm-3">
                                    <div class="p-3 border border-dashed border-start-0">
                                        <h5 class="mb-1">$<span [CountTo]="22.89" class="counter-value" [from]="0"
                                                [duration]="1"></span>k</h5>
                                        <p class="text-muted mb-0">Earnings</p>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-6 col-sm-3">
                                    <div class="p-3 border border-dashed border-start-0">
                                        <h5 class="mb-1"><span [CountTo]="367" class="counter-value" [from]="0"
                                                [duration]="1"></span></h5>
                                        <p class="text-muted mb-0">Refunds</p>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-6 col-sm-3">
                                    <div class="p-3 border border-dashed border-start-0 border-end-0">
                                        <h5 class="mb-1 text-success"><span [CountTo]="18.92" class="counter-value"
                                                [from]="0" [duration]="1"></span>%</h5>
                                        <p class="text-muted mb-0">Conversation Ratio</p>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                        </div><!-- end card header -->

                        <div class="card-body p-0 pb-2">
                            <div class="w-100">
                                <apx-chart dir="ltr" class="apex-charts" [chart]="analyticsChart.chart"
                                    [series]="analyticsChart.series" [legend]="analyticsChart.legend"
                                    [stroke]="analyticsChart.stroke" [colors]="analyticsChart.colors"
                                    [fill]="analyticsChart.fill" [dataLabels]="analyticsChart.dataLabels"
                                    [xaxis]="analyticsChart.xaxis" [markers]="analyticsChart.markers"
                                    [plotOptions]="analyticsChart.plotOptions" [yaxis]="analyticsChart.yaxis"
                                    [tooltip]="analyticsChart.tooltip" [grid]="analyticsChart.grid"
                                    [labels]="analyticsChart.labels" dir="ltr">
                                </apx-chart>
                            </div>
                        </div><!-- end card body -->
                    </div><!-- end card -->
                </div><!-- end col -->

                <div class="col-xl-4">
                    <!-- card -->
                    <div class="card card-height-100">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Sales by Locations</h4>
                            <div class="flex-shrink-0">
                                <button type="button" class="btn btn-soft-primary btn-sm">
                                    Export Report
                                </button>
                            </div>
                        </div><!-- end card header -->

                        <!-- card body -->
                        <div class="card-body">

                            <div id="sales-by-locations" leaflet style="height: 269px;" [leafletOptions]="options"
                                [leafletLayers]="layers"></div>

                            <div class="px-2 py-2 mt-1">
                                <p class="mb-1">Canada <span class="float-end">75%</span></p>
                                <div class="progress mt-2" style="height: 6px;">
                                    <div class="progress-bar progress-bar-striped bg-primary" role="progressbar"
                                        style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="75">
                                    </div>
                                </div>

                                <p class="mt-3 mb-1">Greenland <span class="float-end">47%</span></p>
                                <div class="progress mt-2" style="height: 6px;">
                                    <div class="progress-bar progress-bar-striped bg-primary" role="progressbar"
                                        style="width: 47%" aria-valuenow="47" aria-valuemin="0" aria-valuemax="47">
                                    </div>
                                </div>

                                <p class="mt-3 mb-1">Russia <span class="float-end">82%</span></p>
                                <div class="progress mt-2" style="height: 6px;">
                                    <div class="progress-bar progress-bar-striped bg-primary" role="progressbar"
                                        style="width: 82%" aria-valuenow="82" aria-valuemin="0" aria-valuemax="82">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>

            <div class="row">
                <div class="col-xl-6">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Best Selling Products</h4>
                            <div class="flex-shrink-0">
                                <div class="dropdown card-header-dropdown" ngbDropdown>
                                    <a class="text-reset dropdown-btn arrow-none" href="javascript: void(0);"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        ngbDropdownToggle>
                                        <span class="fw-semibold text-uppercase fs-12">Sort by: </span><span
                                            class="text-muted">Today<i class="mdi mdi-chevron-down ms-1"></i></span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                        <a class="dropdown-item" href="javascript: void(0);">Today</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Yesterday</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Last 7 Days</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Last 30 Days</a>
                                        <a class="dropdown-item" href="javascript: void(0);">This Month</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Last Month</a>
                                    </div>
                                </div>
                            </div>
                        </div><!-- end card header -->

                        <div class="card-body">
                            <div class="table-responsive table-card">
                                <app-best-selling [BestSelling]="BestSelling"></app-best-selling>
                            </div>

                            <div class="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                <div class="col-sm">
                                    <div class="text-muted">Showing<span class="fw-semibold">4</span> of <span
                                            class="fw-semibold">125</span> Results
                                    </div>
                                </div>
                                <div class="col-sm-auto">
                                    <ul
                                        class="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                                        <li class="page-item disabled">
                                            <a href="javascript:void(0);" class="page-link">←</a>
                                        </li>
                                        <li class="page-item">
                                            <a href="javascript:void(0);" class="page-link">1</a>
                                        </li>
                                        <li class="page-item active">
                                            <a href="javascript:void(0);" class="page-link">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a href="javascript:void(0);" class="page-link">3</a>
                                        </li>
                                        <li class="page-item">
                                            <a href="javascript:void(0);" class="page-link">→</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-xl-6">
                    <div class="card card-height-100">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Top Sellers</h4>
                            <div class="flex-shrink-0">
                                <div class="dropdown card-header-dropdown" ngbDropdown>
                                    <a class="text-reset dropdown-btn arrow-none" href="javascript: void(0);"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        ngbDropdownToggle>
                                        <span class="text-muted">Report<i class="mdi mdi-chevron-down ms-1"></i></span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                        <a class="dropdown-item" href="javascript: void(0);">Download Report</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Export</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Import</a>
                                    </div>
                                </div>
                            </div>
                        </div><!-- end card header -->

                        <div class="card-body">
                            <div class="table-responsive table-card">
                                <app-top-selling [TopSelling]="TopSelling"></app-top-selling>
                            </div>

                            <div class="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                <div class="col-sm">
                                    <div class="text-muted">Showing<span class="fw-semibold">4</span> of <span
                                            class="fw-semibold">125</span> Results
                                    </div>
                                </div>
                                <div class="col-sm-auto">
                                    <ul
                                        class="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                                        <li class="page-item disabled">
                                            <a href="javascript:void(0);" class="page-link">←</a>
                                        </li>
                                        <li class="page-item">
                                            <a href="javascript:void(0);" class="page-link">1</a>
                                        </li>
                                        <li class="page-item active">
                                            <a href="javascript:void(0);" class="page-link">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a href="javascript:void(0);" class="page-link">3</a>
                                        </li>
                                        <li class="page-item">
                                            <a href="javascript:void(0);" class="page-link">→</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div> <!-- .card-body-->
                    </div> <!-- .card-->
                </div> <!-- .col-->
            </div> <!-- end row-->

            <div class="row">
                <div class="col-xl-4">
                    <div class="card card-height-100">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Store Visits by Source</h4>
                            <div class="flex-shrink-0">
                                <div class="dropdown card-header-dropdown" ngbDropdown>
                                    <a class="text-reset dropdown-btn arrow-none" href="javascript: void(0);"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        ngbDropdownToggle>
                                        <span class="text-muted">Report<i class="mdi mdi-chevron-down ms-1"></i></span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                        <a class="dropdown-item" href="javascript: void(0);">Download Report</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Export</a>
                                        <a class="dropdown-item" href="javascript: void(0);">Import</a>
                                    </div>
                                </div>
                            </div>
                        </div><!-- end card header -->

                        <div class="card-body">
                            <apx-chart class="apex-charts" dir="ltr" [series]="SalesCategoryChart.series"
                                [labels]="SalesCategoryChart.labels" [chart]="SalesCategoryChart.chart"
                                [legend]="SalesCategoryChart.legend" [stroke]="SalesCategoryChart.stroke"
                                [dataLabels]="SalesCategoryChart.dataLabels" [colors]="SalesCategoryChart.colors"
                                dir="ltr"></apx-chart>
                        </div>
                    </div> <!-- .card-->
                </div> <!-- .col-->

                <div class="col-xl-8">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Recent Orders</h4>
                            <div class="flex-shrink-0">
                                <button type="button" class="btn btn-soft-info btn-sm">
                                    <i class="ri-file-list-3-line align-middle"></i> Generate Report
                                </button>
                            </div>
                        </div><!-- end card header -->

                        <div class="card-body">
                            <div class="table-responsive table-card">
                                <app-recent-orders [Recentelling]="Recentelling"></app-recent-orders>
                            </div>
                        </div>
                    </div> <!-- .card-->
                </div> <!-- .col-->
            </div> <!-- end row-->

        </div> <!-- end .h-100-->

    </div> <!-- end col -->

    <div class="col-auto layout-rightside-col">
        <div class="overlay" (click)="SidebarHide()"></div>
        <div class="layout-rightside">
            <div class="card h-100 rounded-0">
                <div class="card-body p-0">
                    <div class="p-3">
                        <h6 class="text-muted mb-0 text-uppercase fw-semibold">Recent Activity</h6>
                    </div>
                    <ngx-simplebar style="max-height: 410px;" class="p-3 pt-0">
                        <div class="acitivity-timeline acitivity-main">
                            <div class="acitivity-item d-flex">
                                <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                    <div class="avatar-title bg-success-subtle text-success rounded-circle">
                                        <i class="ri-shopping-cart-2-line"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 lh-base">Purchase by James Price</h6>
                                    <p class="text-muted mb-1">Product noise evolve smartwatch </p>
                                    <small class="mb-0 text-muted">02:14 PM Today</small>
                                </div>
                            </div>
                            <div class="acitivity-item py-3 d-flex">
                                <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                    <div class="avatar-title bg-danger-subtle text-danger rounded-circle">
                                        <i class="ri-stack-fill"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 lh-base">Added new <span class="fw-semibold">style collection</span>
                                    </h6>
                                    <p class="text-muted mb-1">By Nesta Technologies</p>
                                    <div class="d-inline-flex gap-2 border border-dashed p-2 mb-2">
                                        <a routerLink="/ecommerce/product-detail/1" class="bg-light rounded p-1">
                                            <img src="assets/images/products/img-8.png" alt=""
                                                class="img-fluid d-block" />
                                        </a>
                                        <a routerLink="/ecommerce/product-detail/1" class="bg-light rounded p-1">
                                            <img src="assets/images/products/img-2.png" alt=""
                                                class="img-fluid d-block" />
                                        </a>
                                        <a routerLink="/ecommerce/product-detail/1" class="bg-light rounded p-1">
                                            <img src="assets/images/products/img-10.png" alt=""
                                                class="img-fluid d-block" />
                                        </a>
                                    </div>
                                    <p class="mb-0 text-muted"><small>9:47 PM Yesterday</small></p>
                                </div>
                            </div>
                            <div class="acitivity-item py-3 d-flex">
                                <div class="flex-shrink-0">
                                    <img src="assets/images/users/avatar-2.jpg" alt=""
                                        class="avatar-xs rounded-circle acitivity-avatar">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 lh-base">Natasha Carey have liked the products</h6>
                                    <p class="text-muted mb-1">Allow users to like products in your WooCommerce store.
                                    </p>
                                    <small class="mb-0 text-muted">25 Dec, 2021</small>
                                </div>
                            </div>
                            <div class="acitivity-item py-3 d-flex">
                                <div class="flex-shrink-0">
                                    <div class="avatar-xs acitivity-avatar">
                                        <div class="avatar-title rounded-circle bg-secondary">
                                            <i class="mdi mdi-sale fs-14"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 lh-base">Today offers by <a href="ecommerce/seller-details"
                                            class="link-secondary">Digitech Galaxy</a></h6>
                                    <p class="text-muted mb-2">Offer is valid on orders of Rs.500 Or above for selected
                                        products only.</p>
                                    <small class="mb-0 text-muted">12 Dec, 2021</small>
                                </div>
                            </div>
                            <div class="acitivity-item py-3 d-flex">
                                <div class="flex-shrink-0">
                                    <div class="avatar-xs acitivity-avatar">
                                        <div class="avatar-title rounded-circle bg-danger-subtle text-danger">
                                            <i class="ri-bookmark-fill"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 lh-base">Favoried Product</h6>
                                    <p class="text-muted mb-2">Esther James have favorited product.</p>
                                    <small class="mb-0 text-muted">25 Nov, 2021</small>
                                </div>
                            </div>
                            <div class="acitivity-item py-3 d-flex">
                                <div class="flex-shrink-0">
                                    <div class="avatar-xs acitivity-avatar">
                                        <div class="avatar-title rounded-circle bg-secondary">
                                            <i class="mdi mdi-sale fs-14"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 lh-base">Flash sale starting <span
                                            class="text-primary">Tomorrow.</span></h6>
                                    <p class="text-muted mb-0">Flash sale by <a href="javascript:void(0);"
                                            class="link-secondary fw-medium">Zoetic Fashion</a></p>
                                    <small class="mb-0 text-muted">22 Oct, 2021</small>
                                </div>
                            </div>
                            <div class="acitivity-item py-3 d-flex">
                                <div class="flex-shrink-0">
                                    <div class="avatar-xs acitivity-avatar">
                                        <div class="avatar-title rounded-circle bg-info-subtle text-info">
                                            <i class="ri-line-chart-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 lh-base">Monthly sales report</h6>
                                    <p class="text-muted mb-2"><span class="text-danger">2 days left</span> notification
                                        to submit the monthly sales report. <a href="javascript:void(0);"
                                            class="link-warning text-decoration-underline">Reports Builder</a></p>
                                    <small class="mb-0 text-muted">15 Oct</small>
                                </div>
                            </div>
                            <div class="acitivity-item d-flex">
                                <div class="flex-shrink-0">
                                    <img src="assets/images/users/avatar-3.jpg" alt=""
                                        class="avatar-xs rounded-circle acitivity-avatar" />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1 lh-base">Frank Hook Commented</h6>
                                    <p class="text-muted mb-2 fst-italic">" A product that has reviews is more likable
                                        to be sold than a product. "</p>
                                    <small class="mb-0 text-muted">26 Aug, 2021</small>
                                </div>
                            </div>
                        </div>
                    </ngx-simplebar>

                    <div class="p-3 mt-2">
                        <h6 class="text-muted mb-3 text-uppercase fw-semibold">Top 10 Categories</h6>

                        <ol class="ps-3 text-muted">
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Mobile & Accessories <span
                                        class="float-end">(10,294)</span></a>
                            </li>
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Desktop <span
                                        class="float-end">(6,256)</span></a>
                            </li>
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Electronics <span
                                        class="float-end">(3,479)</span></a>
                            </li>
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Home & Furniture <span
                                        class="float-end">(2,275)</span></a>
                            </li>
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Grocery <span
                                        class="float-end">(1,950)</span></a>
                            </li>
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Fashion <span
                                        class="float-end">(1,582)</span></a>
                            </li>
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Appliances <span
                                        class="float-end">(1,037)</span></a>
                            </li>
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Beauty, Toys & More <span
                                        class="float-end">(924)</span></a>
                            </li>
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Food & Drinks <span
                                        class="float-end">(701)</span></a>
                            </li>
                            <li class="py-1">
                                <a href="javascript: void(0);" class="text-muted">Toys & Games <span
                                        class="float-end">(239)</span></a>
                            </li>
                        </ol>
                        <div class="mt-3 text-center">
                            <a href="javascript:void(0);" class="text-muted text-decoration-underline">View all
                                Categories</a>
                        </div>
                    </div>
                    <div class="p-3">
                        <h6 class="text-muted mb-3 text-uppercase fw-semibold">Products Reviews</h6>
                        <!-- Swiper -->
                        <div class="swiper vertical-swiper rounded" style="height: 280px;">
                            <swiper [config]="Vertical" style="height: 280px; width: auth;">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <div class="card border border-dashed shadow-none m-0">
                                            <div class="card-body">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 avatar-sm">
                                                        <div class="avatar-title bg-light rounded">
                                                            <img src="assets/images/companies/img-1.png" alt=""
                                                                height="30">
                                                        </div>
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <div>
                                                            <p
                                                                class="text-muted mb-1 fst-italic text-truncate-two-lines">
                                                                "
                                                                Great product and looks great, lots of features. "</p>
                                                            <div class="fs-11 align-middle text-warning">
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                            </div>
                                                        </div>
                                                        <div class="text-end mb-0 text-muted">
                                                            - by <cite title="Source Title">Force Medicines</cite>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="card border border-dashed shadow-none m-0">
                                            <div class="card-body">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0">
                                                        <img src="assets/images/users/avatar-3.jpg" alt=""
                                                            class="avatar-sm rounded">
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <div>
                                                            <p
                                                                class="text-muted mb-1 fst-italic text-truncate-two-lines">
                                                                "
                                                                Amazing template, very easy to understand and
                                                                manipulate. "
                                                            </p>
                                                            <div class="fs-11 align-middle text-warning">
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-half-fill"></i>
                                                            </div>
                                                        </div>
                                                        <div class="text-end mb-0 text-muted">
                                                            - by <cite title="Source Title">Henry Baird</cite>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="card border border-dashed shadow-none m-0">
                                            <div class="card-body">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 avatar-sm">
                                                        <div class="avatar-title bg-light rounded">
                                                            <img src="assets/images/companies/img-8.png" alt=""
                                                                height="30">
                                                        </div>
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <div>
                                                            <p
                                                                class="text-muted mb-1 fst-italic text-truncate-two-lines">
                                                                "Very beautiful product and Very helpful customer
                                                                service."
                                                            </p>
                                                            <div class="fs-11 align-middle text-warning">
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-line"></i>
                                                                <i class="ri-star-line"></i>
                                                            </div>
                                                        </div>
                                                        <div class="text-end mb-0 text-muted">
                                                            - by <cite title="Source Title">Zoetic Fashion</cite>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="card border border-dashed shadow-none m-0">
                                            <div class="card-body">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0">
                                                        <img src="assets/images/users/avatar-2.jpg" alt=""
                                                            class="avatar-sm rounded">
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <div>
                                                            <p
                                                                class="text-muted mb-1 fst-italic text-truncate-two-lines">
                                                                "
                                                                The product is very beautiful. I like it. "</p>
                                                            <div class="fs-11 align-middle text-warning">
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-fill"></i>
                                                                <i class="ri-star-half-fill"></i>
                                                                <i class="ri-star-line"></i>
                                                            </div>
                                                        </div>
                                                        <div class="text-end mb-0 text-muted">
                                                            - by <cite title="Source Title">Nancy Martino</cite>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </swiper>
                        </div>
                    </div>

                    <div class="p-3">
                        <h6 class="text-muted mb-3 text-uppercase fw-semibold">Customer Reviews</h6>
                        <div class="bg-light px-3 py-2 rounded-2 mb-2">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <div class="fs-16 align-middle text-warning">
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-half-fill"></i>
                                    </div>
                                </div>
                                <div class="flex-shrink-0">
                                    <h6 class="mb-0">4.5 out of 5</h6>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="text-muted">Total <span class="fw-medium">5.50k</span> reviews</div>
                        </div>

                        <div class="mt-3">
                            <div class="row align-items-center g-2">
                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0">5 star</h6>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-1">
                                        <div class="progress animated-progess progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar"
                                                style="width: 50.16%" aria-valuenow="50.16" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0 text-muted">2758</h6>
                                    </div>
                                </div>
                            </div>
                            <!-- end row -->

                            <div class="row align-items-center g-2">
                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0">4 star</h6>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-1">
                                        <div class="progress animated-progess progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar"
                                                style="width: 29.32%" aria-valuenow="29.32" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0 text-muted">1063</h6>
                                    </div>
                                </div>
                            </div>
                            <!-- end row -->

                            <div class="row align-items-center g-2">
                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0">3 star</h6>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-1">
                                        <div class="progress animated-progess progress-sm">
                                            <div class="progress-bar bg-warning" role="progressbar"
                                                style="width: 18.12%" aria-valuenow="18.12" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0 text-muted">997</h6>
                                    </div>
                                </div>
                            </div>
                            <!-- end row -->

                            <div class="row align-items-center g-2">
                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0">2 star</h6>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-1">
                                        <div class="progress animated-progess progress-sm">
                                            <div class="progress-bar bg-success" role="progressbar" style="width: 4.98%"
                                                aria-valuenow="4.98" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0 text-muted">227</h6>
                                    </div>
                                </div>
                            </div>
                            <!-- end row -->

                            <div class="row align-items-center g-2">
                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0">1 star</h6>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-1">
                                        <div class="progress animated-progess progress-sm">
                                            <div class="progress-bar bg-danger" role="progressbar" style="width: 7.42%"
                                                aria-valuenow="7.42" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="p-1">
                                        <h6 class="mb-0 text-muted">408</h6>
                                    </div>
                                </div>
                            </div><!-- end row -->
                        </div>
                    </div>

                    <div class="card sidebar-alert bg-light border-0 text-center mx-4 mb-0 mt-3">
                        <div class="card-body">
                            <img src="assets/images/giftbox.png" alt="">
                            <div class="mt-4">
                                <h5>Invite New Seller</h5>
                                <p class="text-muted lh-base">Refer a new seller to us and earn $100 per refer.</p>
                                <button type="button" class="btn btn-primary btn-label rounded-pill"><i
                                        class="ri-mail-fill label-icon align-middle rounded-pill fs-16 me-2"></i> Invite
                                    Now</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div> <!-- end card-->
        </div> <!-- end .rightbar-->

    </div> <!-- end col -->
</div>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
import { Injectable } from '@angular/core';
import { LayoutStorageService } from './layout-storage.service';
import { UserPreferencesService } from 'src/app/services/user-preferences.services';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    /**
     *
     */
    constructor(private layoutStorageService: LayoutStorageService, private userPreferencesService: UserPreferencesService) {
    }
    async changeMode(mode: any) {
        switch (mode) {
          case 'light':
            document.documentElement.setAttribute('data-bs-theme', "light");
            document.documentElement.setAttribute('data-sidebar', "light");
            break;
          case 'dark':
            document.documentElement.setAttribute('data-bs-theme', "dark");
            document.documentElement.setAttribute('data-sidebar', "dark");
            break;
          default:
            document.documentElement.setAttribute('data-bs-theme', "light");
            break;
        }

        let layout = this.layoutStorageService.get();
        layout.portalPreferences.LAYOUT_MODE = mode;
        this.layoutStorageService.save(layout);

        this.userPreferencesService.updatePortalPreferences();
      }
}